export const SET_SCRAPPED_DATA = "SET_SCRAPPED_DATA";
export const DATA_SUBMIT_SCRAP_INIT = "DATA_SUBMIT_SCRAP_INIT";
export const UPLOAD_SCRAPPED_FILE_COMPLETE = "UPLOAD_SCRAPPED_FILE_COMPLETE"
export const DATA_SUBMIT_DISMISS = "DATA_SUBMIT_DISMISS";
export const CLOSE_NEW_UPLOAD_POPUP = "CLOSE_NEW_UPLOAD_POPUP";
export const SCRAP_UPLOAD_LOACTION_LIST_COMPLETE = "SCRAP_UPLOAD_LOACTION_LIST_COMPLETE";
export const SCRAPPED_DATA_FETCHING_INIT = "SCRAPPED_DATA_FETCHING_INIT";
export const FETCH_SCRAPPED_LIST_COMPLETE = "FETCH_SCRAPPED_LIST_COMPLETE";
export const SCRAPPED_FILE_DATA_FETCHING_DISMISS = "SCRAPPED_FILE_DATA_FETCHING_DISMISS";
export const HIDE_SCRAP_VALIDATION_FILE_REJECT = "HIDE_SCRAP_VALIDATION_FILE_REJECT";
export const OPEN_SCRAP_REJECT_VALIDATION_POPUP = "OPEN_SCRAP_REJECT_VALIDATION_POPUP";
export const SCRAP_STATUS_FETCHING_INIT = "SCRAP_STATUS_FETCHING_INIT";
export const SCRAP_STATUS_FETCHING_DISMISS = "SCRAP_STATUS_FETCHING_DISMISS";
export const SCRAP_STATUS_FETCH_COMPLETE = "SCRAP_STATUS_FETCH_COMPLETE";
export const ACCEPT_SCRAP_VALIDATION_SUCCESS = "ACCEPT_SCRAP_VALIDATION_SUCCESS";
export const SCRAP_REJECT_VALIDATION_COMPLETE = "SCRAP_REJECT_VALIDATION_COMPLETE";
export const VIEW_DETAILS_SCRAP_FETCHING_INIT = "VIEW_DETAILS_SCRAP_FETCHING_INIT";
export const VIEW_DETAILS_SCRAP_FETCHING_COMPLETE = "VIEW_DETAILS_SCRAP_FETCHING_COMPLETE";
export const VIEW_DETAILS_SCRAP_FETCHING_DISMISS = "VIEW_DETAILS_SCRAP_FETCHING_DISMISS";
export const SCRAP_NEXT_STEP_VIEW_COMPLETE = "SCRAP_NEXT_STEP_VIEW_COMPLETE";
export const REMOVE_ERR_RECORDS_COMPLETE = "REMOVE_ERR_RECORDS_COMPLETE";
export const SCRAP_ASSET_FETCHING_INIT = "SCRAP_ASSET_FETCHING_INIT";
export const SCRAP_ASSET_FETCHING_COMPLETE = "SCRAP_ASSET_FETCHING_COMPLETE";
export const SCRAP_ASSET_FETCHING_DISMISS = "SCRAP_ASSET_FETCHING_DISMISS";
export const SCRAP_EDIT_ERROR_RECORD_PREVIEW = "SCRAP_EDIT_ERROR_RECORD_PREVIEW";
export const SCRAP_EDIT_ERROR_RECORD = "SCRAP_EDIT_ERROR_RECORD";
export const SCRAP_EDIT_ERROR_RECORD_DISMISS = "SCRAP_EDIT_ERROR_RECORD_DISMISS";
export const DATA_ERR_REFETCH_SUBMIT_INIT = "DATA_ERR_REFETCH_SUBMIT_INIT"
export const DATA_ERR_REFETCH_COMPLETE = "DATA_ERR_REFETCH_COMPLETE"
export const DATA_FETCHING_HISTORY_INIT_SCRAP = "DATA_FETCHING_HISTORY_INIT_SCRAP";
export const DATA_HISTORY_FETCHING_DISMISS_SCRAP = "DATA_HISTORY_FETCHING_DISMISS_SCRAP";
export const ASSET_HISTORY_SEARCH_LIST_COMPLETE_SCRAP = "ASSET_HISTORY_SEARCH_LIST_COMPLETE_SCRAP";
export const ADD_ASSET_EVENT_INIT = "ADD_ASSET_EVENT_INIT";
export const ADD_ASSET_EVENT_COMPLTED = "ADD_ASSET_EVENT_COMPLTED";
export const ADD_ASSET_EVENT_DISSMISS = "ADD_ASSET_EVENT_DISSMISS";
export const DELETE_ASSET_EVENT_INIT="DELETE_ASSET_EVENT_INIT";
export const DELETE_ASSET_EVENT_COMPLETE="DELETE_ASSET_EVENT_COMPLETE";
export const DELETE_ASSET_EVENT_DISMISS="DELETE_ASSET_EVENT_DISMISS";
export const EDIT_ASSET_EVENT_INIT="EDIT_ASSET_EVENT_INIT";
export const SCRAP_APPROVE_REJECT_FETCHING_INIT="SCRAP_APPROVE_REJECT_FETCHING_INIT";
export const SCRAP_APPROVE_REJECT_FETCHING_COMPLETE="SCRAP_APPROVE_REJECT_FETCHING_COMPLETE";
export const SCRAP_APPROVE_REJECT_FETCHING_DISMISS="SCRAP_APPROVE_REJECT_FETCHING_DISMISS";
export const ACCEPT_SCRAP_ACCEPT_REJECT_INIT="ACCEPT_SCRAP_ACCEPT_REJECT_INIT";
export const ACCEPT_SCRAP_ACCEPT_REJECT_COMPLETE="ACCEPT_SCRAP_ACCEPT_REJECT_COMPLETE";
export const ACCEPT_SCRAP_ACCEPT_REJECT_DISMISS = "ACCEPT_SCRAP_ACCEPT_REJECT_DISMISS";
export const SCRAP_USER_ASSIGN_NOTI_COMPLETE = "SCRAP_USER_ASSIGN_NOTI_COMPLETE";
export const ERR_MSG_FETCHING_INIT = "ERR_MSG_FETCHING_INIT";
export const ERR_MSG_FETCHING_DISMISS = "ERR_MSG_FETCHING_DISMISS";
const setScrapped = {
    data_fetching_scrapped: true,
    scrapped_file_list: [],
    scrap_upload_flag: false,
    scrapped_files: [],
    scrapped_file_title: "",
    upld_btn_disable: false,
    location: null,
    location_lists: [],
    search: "",
    page: 1,
    perpage: 10,
    filter_status: null,
    scrapped_list_refetch: true,
    scrap_status: [],
    scrap_status_fetch: false,
    scrap_file_status_refetch: false,
    scrap_steps_fetching: false,
    scrap_status_retry: false,
    scrap_file_id: null,
    current_scrap_step: 0,
    isScrapApiFetching: false,
    scrap_next_stp_enable: false,
    scrap_reject_popup: false,
    scrap_reject_confirm_btn_disable: false,
    scrap_approve_popup: false,
    scrap_approve_btn_disable: false,
    scrap_view: 0,
    scrap_view_details_page: 1,
    scrap_view_details_refetch: false,
    scrap_view_details_perpage: 10,
    scrap_view_details_search: "",
    total_scrap_view_data: 0,
    scrap_view_details_fetching: false,
    scrap_view_details_list: [],
    show_scrap_reject_data: false,
    export_scrap_View_details_support_flag_value: 1,
    download_scrap_view_detail_support_flag: false,
    scrap_remove_popup: false,
    remove_err_popup_count: 0,
    single_scrap_err_id: "",
    rem_err_btn_disable: false,
    show_reject_data: false,
    scrapfileassetlist_refetch: false,
    data_fetching: false,
    search_view: "",
    viewpage: 1,
    viewperpage: 10,
    scrapfileasset_list: [],
    total_scrapfileasset: 0,
    scrapfilelist_refetch: false,
    e_scrapped_date: "",
    e_cod: "",
    e_serial_no: "",
    edit_err_btn: false,
    editScrapErrData: null,
    temp_id: null,
    count: 1000,
    asset_history_data: [],
    total_asset_data_count: 0,
    asset_history_data_fetching: false,
    asset_status_complete: false,
    assetHistorySyncFlag: null,
    assethistorySyncDate: null,
    dlt_btn_disble: false,
    serial_number: "",
    asset_history_details_refetch: false,
    eventCompleted: false,
    is_edit: -1,
    asset_details_history: {},
    asset_event_edit_popup: false,
    asset_event_add_popup: false,
    asset_event_delete_popup: false,
    scrap_his_add_btn_dsbl: false,
    scrap_event_type: "",
    scrap_his_event_type: "",
    scrap_his_event_date: "",
    scrap_his_event_code: "",
    scrap_his_mpan: "",
    scrap_his_mprn: "",
    scrap_his_address: "",
    scrap_his_postcode: "",
    scrap_his_event_cod: "",
    scrap_his_event_location:"",
    module: 3,
    scrap_his_edit_btn_dsbl:false,
    edit_scrap_his_event_date:"",
    edit_scrap_his_event_type:"",
    edit_scrap_his_supplier_code:"",
    edit_scrap_his_mpan:"",
    edit_scrap_his_mprn:"",
    s_scrap_his_mpan:"",
    s_scrap_his_mprn:"",
    edit_scrap_his_postcode:"",
    edit_scrap_his_address:"",
    edit_scrap_his_event_cod: "",
    edit_scrap_his_event_location:"",
    isScrapAccepted:false,
    scrap_approve_reject_page:1,
    scrap_approve_reject_per_page:10,
    scrap_approve_reject_view_fetching:false,
    scrap_approve_reject_view_data:[],
    scrap_approve_reject_total_view_data:0,
    scrap_approve_reject_view_search:"",
    scrap_approve_reject_view_refetch:false,
    acceptable_status:0,
    scrap_single_approve_btn_disable:false,
    scrap_viewdetails_refetch:true,
    editPopupClose:false,
    socket_refresh:false,
    scrap_rec_id:0,
    socket_data:[],
    scrap_socket_id:"",
    asset_id:null,
    assign_notification:"",
    user_assigned:null,
    scrap_asset_data:[],
    scrap_view_err_search:"",
    scrap_view_err_page:1,
    scrap_view_err_perpage:10,
    scrap_view_err_list:[],
    selected_scrap_err_msg:"",
    scrap_err_msg_list:[],
    scrap_view_err_refetch:true
}

export default (state = setScrapped, action = {}) => {
    const actionHandlers = {
        SET_SCRAPPED_DATA: () => ({ ...state, ...action.payload }),
        DATA_SUBMIT_SCRAP_INIT: () => ({ ...state, upld_btn_disable: true }),
        UPLOAD_SCRAPPED_FILE_COMPLETE: () => ({
            ...state,
            scrapped_files: [],
            scrapped_file_title: "",
            location: [],
            upld_btn_disable: false,
            scrap_upload_flag: false,
            scrap_view: 1,
            scrap_file_status_refetch: true,
            scrapped_list_refetch:true,
            scrap_status_fetch: true,
            ...action.payload
        }),
        DATA_SUBMIT_DISMISS: () => ({ ...state, upld_btn_disable: false }),
        CLOSE_NEW_UPLOAD_POPUP: () => ({
            ...state,
            location: null,
            scrapped_file_title: "",
            scrapped_files: [],
            scrap_upload_flag: false,
        }),
        SCRAP_UPLOAD_LOACTION_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
        SCRAPPED_DATA_FETCHING_INIT: () => ({ ...state, data_fetching_scrapped: true }),
        FETCH_SCRAPPED_LIST_COMPLETE: () => ({ ...state, ...action.payload }),
        SCRAPPED_FILE_DATA_FETCHING_DISMISS: () => ({ ...state, data_fetching_scrapped: false }),
        OPEN_SCRAP_REJECT_VALIDATION_POPUP: () => ({ ...state, ...action.payload, scrap_reject_popup: true }),
        HIDE_SCRAP_VALIDATION_FILE_REJECT: () => ({ ...state, ...action.payload, scrap_reject_popup: false }),
        SCRAP_STATUS_FETCHING_INIT: () => ({ ...state, ...action.payload, isScrapApiFetching: true }),
        SCRAP_STATUS_FETCHING_DISMISS: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_file_status_refetch: false, 
            scrap_status_fetch: false, 
            scrap_status_retry: false, 
            isScrapApiFetching: false 
        }),
        SCRAP_STATUS_FETCH_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_file_status_refetch: false, 
            scrap_status_fetch: false, 
            isScrapApiFetching: false, 
            scrap_status_retry: false, 
            direct_to_steps: false, 
            scrap_next_stp_enable: false 
        }),
        ACCEPT_SCRAP_VALIDATION_SUCCESS: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_approve_popup: false, 
            scrap_approve_btn_disable: false, 
            scrap_view: 0, 
            scrap_approve_reject_page:1,
            scrapped_list_refetch: true ,
            scrap_approve_reject_view_search:""
        }),
        SCRAP_REJECT_VALIDATION_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_view: 0, 
            scrapped_list_refetch: true, 
            scrap_reject_confirm_btn_disable: false,
            scrap_approve_reject_page:1,
            scrap_approve_reject_view_search:""
        }),
        VIEW_DETAILS_SCRAP_FETCHING_INIT: () => ({ ...state, ...action.payload, scrap_view_details_fetching: true }),
        VIEW_DETAILS_SCRAP_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, scrap_view_details_refetch: false }),
        VIEW_DETAILS_SCRAP_FETCHING_DISMISS: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_view_details_refetch: false, 
            scrap_view_details_fetching: false,
        }),
        SCRAP_NEXT_STEP_VIEW_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_file_status_refetch: true, 
            scrap_steps_fetching: false 
        }),
        REMOVE_ERR_RECORDS_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_view_details_fetching: false, 
            socket_refresh: true, 
            rem_err_btn_disable: false, 
            remove_err_popup_count: 0 ,
        }),
        SCRAP_ASSET_FETCHING_INIT: () => ({ ...state, ...action.payload, data_fetching: true }),
        SCRAP_ASSET_FETCHING_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            scrapfileassetlist_refetch: false, 
            data_fetching: false 
        }),
        SCRAP_ASSET_FETCHING_DISMISS: () => ({ 
            ...state, 
            ...action.payload, 
            scrapfileassetlist_refetch: false, 
            data_fetching: false 
        }),
        SCRAP_EDIT_ERROR_RECORD_PREVIEW: () => ({ ...state, ...action.payload, edit_err_btn: true }),
        SCRAP_EDIT_ERROR_RECORD: () => ({ ...state, ...action.payload, edit_err_btn: false, socket_refresh: true,editPopupClose:false}),
        SCRAP_EDIT_ERROR_RECORD_DISMISS: () => ({ ...state, ...action.payload, edit_err_btn: false }),
        DATA_ERR_REFETCH_SUBMIT_INIT: () => ({ ...state, ...action.payload }),
        DATA_ERR_REFETCH_COMPLETE: () => ({ ...state, ...action.payload }),
        DATA_FETCHING_HISTORY_INIT_SCRAP: () => ({ ...state, ...action.payload, asset_history_data_fetching: true }),
        DATA_HISTORY_FETCHING_DISMISS_SCRAP: () => ({ 
            ...state, 
            ...action.payload, 
            asset_history_data_fetching: false, 
            asset_history_details_refetch: false 
        }),
        ASSET_HISTORY_SEARCH_LIST_COMPLETE_SCRAP: () => ({ ...state, ...action.payload }),
        ADD_ASSET_EVENT_INIT: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_his_add_btn_dsbl: true, 
            asset_status_complete: false, 
            eventCompleted: false 
        }),
        ADD_ASSET_EVENT_COMPLTED: () => ({
            ...state, 
            ...action.payload, 
            scrap_his_event_type: "",
            scrap_his_event_date: "",
            scrap_his_event_code: "",
            scrap_his_mpan: "",
            scrap_his_mprn: "",
            scrap_his_address: "",
            scrap_his_postcode: "",
            scrap_his_event_cod: "",
            scrap_his_event_location:"",
            asset_history_details_refetch: true,
            asset_status_complete: true,
            eventCompleted: true
        }),
        ADD_ASSET_EVENT_DISSMISS: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_his_add_btn_dsbl: false, 
            asset_status_complete: false, 
            eventCompleted: false 
        }),
        DELETE_ASSET_EVENT_INIT: () => ({ 
            ...state, 
            ...action.payload, 
            dlt_btn_disble: true, 
            asset_status_complete: false, 
            eventCompleted: false 
        }),
        DELETE_ASSET_EVENT_COMPLETE: () => ({ 
            ...state, 
            ...action.payload, 
            dlt_btn_disble: false,
            asset_history_details_refetch: true, 
            asset_status_complete: true, 
            eventCompleted: true 
        }),
        DELETE_ASSET_EVENT_DISMISS: () => ({ 
            ...state, 
            ...action.payload, 
            dlt_btn_disble: false, 
            asset_status_complete: false, 
            eventCompleted: false 
        }),
        EDIT_ASSET_EVENT_INIT: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_his_edit_btn_dsbl: true, 
            asset_status_complete: false, 
            eventCompleted: false 
        }),
        SCRAP_APPROVE_REJECT_FETCHING_INIT: () => ({ ...state, ...action.payload, scrap_approve_reject_view_fetching: true,scrap_view_details_fetching:true }),
        ACCEPT_SCRAP_ACCEPT_REJECT_INIT: () => ({ ...state, ...action.payload,scrap_single_approve_btn_disable:true}),
        ACCEPT_SCRAP_ACCEPT_REJECT_COMPLETE: () => ({ ...state, ...action.payload}),
        ACCEPT_SCRAP_ACCEPT_REJECT_DISMISS: () => ({ ...state, ...action.payload, scrap_approve_btn_disable:false,scrap_single_approve_btn_disable:false}),
        SCRAP_APPROVE_REJECT_FETCHING_COMPLETE: () => ({ ...state, ...action.payload, scrap_approve_reject_view_refetch: false }),
        SCRAP_APPROVE_REJECT_FETCHING_DISMISS: () => ({ 
            ...state, 
            ...action.payload, 
            scrap_approve_reject_view_refetch: false, 
            scrap_approve_reject_view_fetching: false,
            scrap_view_details_fetching:false
        }),
        SCRAP_USER_ASSIGN_NOTI_COMPLETE: (()=> ({ ...state, ...action.payload,socket_refresh:false})),
        ERR_MSG_FETCHING_INIT : ()=>{
            return { ...state, ...action.payload };
        },
        ERR_MSG_FETCHING_DISMISS : ()=>{
            return { ...state, ...action.payload };
        },
    };

    if (actionHandlers[action.type] === undefined) {
        return state; // Return unchanged state
    } else {
        // Call the appropriate action handler and return the updated state
        return actionHandlers[action.type]();
    }
};
