import React from "react";
import "@fluentui/react/dist/css/fabric.css";
import "../../styles/dashboard/layout.css";
import { loadTheme } from "@fluentui/react";
import { initializeIcons } from '@fluentui/font-icons-mdl2'
import themesettings from "./theme-config";
import PropTypes from 'prop-types';

function WithTheme(props) {
  //load MS icons throughout the application
  initializeIcons();
  //set theme overrides
  //design themes from  here -> https://aka.ms/themedesigner
  loadTheme(themesettings);
  return <>{props.children}</>;
}
WithTheme.propTypes = {
  children: PropTypes.object || {}
}
export default WithTheme;
